function maskNumber(value, mask) {
  for (const char of value) mask = mask.replace(/#/, char)
  return mask.replace(/\D+$/g, '')
}

function unmaskNumber(value) {
  return value.replace(/\D/g, '')
}

export default function maskCpf(value) {
  if (!value) return

  value = unmaskNumber(value)

  return maskNumber(value, '###.###.###-##')
}
