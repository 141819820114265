import React from 'react'
import { Head } from '~/components'
import Menu from '~/components/Menu'
import Banner from '~/components/Banner'

import bannerAssinatura from '~/assets/img/banner-assinatura.jpg'
import Showcase from '~/components/Showcase'
import Footer from '~/components/Footer'
import CarList from '~/components/CarList'
import { PageProps } from 'gatsby'

import { useApi } from '~/siteApi'
interface Context {
  banners: Array<never>
  vehicles: Array<never>
  deadlines: Array<never>
  franchises: Array<never>
}

const AssinaturaDeVeiculos = ({ pageContext }: PageProps) => {
  const { banners, vehicles, deadlines, franchises }: Context = useApi(
    pageContext,
    'page-home'
  )

  return (
    <>
      <Head />
      <Banner
        image={bannerAssinatura}
        title="Veículo por assinatura é na Dimas Sempre"
        buttonText="Confira os veículos"
        anchor="/assinatura-de-veiculos/#veiculos"
        maxWidth={840}
      />
      <CarList
        vehicles={vehicles}
        deadlines={deadlines}
        franchises={franchises}
      />
      <Showcase banners={banners} />
      <Footer />
    </>
  )
}

export default AssinaturaDeVeiculos
