import React, { useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { scroller } from 'react-scroll'
import cn from 'classnames'
import electrical from '~/assets/img/svg/electric-car.svg'
import formatPhone from '~/utils/formatPhone'
import maskCpf from '~/utils/formatCpf'
import maskCep from '~/utils/formatCep'
import requestData from '~/siteApi/form.js'

import * as st from '~/assets/styl/CarList.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { LazyImage } from 'react-lazy-images'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'

interface Props {
  vehicles: Array<{
    id: number
    title: string
    subtitle: string
    electric: boolean
    bodywork: string
    description: string[]
    price: string
    observation: string
    image: string
  }>

  deadlines: Array<{
    id: number
    title: string
  }>

  franchises: Array<{
    id: number
    title: string
  }>
}

export default function CarList({ vehicles, deadlines, franchises }: Props) {
  const [stepActive, setStepActive] = useState(1)
  const [inputStep, setInputStep] = useState(1)
  const [carSelected, setCarSelected] = useState('')
  const [carSubtitle, setCarSubtitle] = useState('')
  const [carElectric, setCarElectric] = useState(false)
  const [appDriver, setAppDriver] = useState('')
  const [carImage, setCarImage] = useState('')
  const [time, setTime] = useState('')
  const [franchisee, setFranchisee] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [cep, setCep] = useState('')
  const [completeForm, setCompleteForm] = useState(false)
  const [phone, setPhone] = useState('')
  const [termo, setTermo] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [communications, setCommunications] = useState(false)
  const terms_of_use = termo
  const privacy_policy = privacy
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [openTooltip, setOpenTooltip] = useState(false)
  const submitBtns = [
    useRef<HTMLButtonElement>(),
    useRef<HTMLButtonElement>(),
    useRef<HTMLButtonElement>(),
    useRef<HTMLButtonElement>(),
    useRef<HTMLButtonElement>(),
    useRef<HTMLButtonElement>(),
    useRef<HTMLButtonElement>(),
  ]
  const [cookies] = useCookies()
  const Veiculo = `${carSelected} - ${carSubtitle}`
  const Prazo = time
  const Franquia = franchisee
  const Cpf = cpf
  const Cep = cep
  const Celular = phone

  function handleSelectedCar(e, carImage, carName, carSubtitle, carElectric) {
    e.preventDefault()

    setCarImage(carImage)
    setCarSelected(carName)
    setCarSubtitle(carSubtitle)
    setCarElectric(carElectric)
    setStepActive(stepActive + 1)

    scroller.scrollTo('veiculos', {
      duration: 500,
      smooth: true,
    })
  }

  function handleChangeVehicle(e) {
    e.preventDefault()

    setCompleteForm(false)

    stepActive === 3
      ? setStepActive(stepActive - 2)
      : setStepActive(stepActive - 1)

    setAppDriver('')
    setTime('')
    setInputStep(1)
    setFranchisee('')
  }

  function handleStep(e) {
    e.preventDefault()

    submitBtns[0].current.click()
    submitBtns[1].current.click()

    if (time && franchisee) {
      setStepActive(stepActive + 1)
    }

    scroller.scrollTo('veiculos', {
      duration: 500,
      smooth: true,
    })
  }

  function handleAppDriver(e) {
    setAppDriver(e.target.value)
  }

  function handleNameStep(e) {
    e.preventDefault()

    submitBtns[2].current.click()

    if (name !== '') {
      setInputStep(inputStep + 1)
    }
  }

  function handleEmailStep(e) {
    e.preventDefault()

    submitBtns[3].current.click()

    if (email !== '' && email.includes('@')) {
      setInputStep(inputStep + 1)
    }
  }

  function handleCpfStep(e) {
    e.preventDefault()

    submitBtns[4].current.click()

    setInputStep(inputStep + 1)
  }

  function handleCepStep(e) {
    e.preventDefault()

    submitBtns[5].current.click()

    if (cep != '') {
      setInputStep(inputStep + 1)
    }
  }

  function handleInputStep(e) {
    e.preventDefault()

    submitBtns[6].current.click()
    setCompleteForm(true)

    if (phone.length >= 14) {
      setInputStep(inputStep + 1)
    }
  }

  function handleCarEdit(e) {
    e.preventDefault()
    setStepActive(1)
    setInputStep(1)
  }

  function handlePlanEdit(e) {
    e.preventDefault()
    setStepActive(2)
    setInputStep(1)
  }

  function handleDataEdit(e) {
    e.preventDefault()
    setInputStep(1)
  }

  function handleTooltip(e) {
    e.preventDefault()
    setOpenTooltip(!openTooltip)
  }

  function handleTermo(e) {
    setTermo(e.target.checked)
    setPrivacy(e.target.checked)
  }

  function handleComunicacao(e) {
    setCommunications(e.target.checked)
  }

  function handleStepSubmit(e) {
    e.preventDefault()
  }

  const handleSubmitAssign = (e) => {
    e.preventDefault()
    requestData('post', 'conversions', {
      time,
      franchisee,
      name,
      email,
      cpf,
      cep,
      phone,
      legal_bases: [
        {
          category: 'communications',
          type: 'consent',
          status: 'granted',
        },
        {
          category: 'data_processing',
          type: 'consent',
          status: 'granted',
        },
      ],
      token_rdstation: '36ef8b1445846a96de8abe06eaf566be',
      identificador: 'Site - Simulação',
      traffic_source: cookies?.['__trf.src'] || cookies?.__utmzz,
      __utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    })
    handleInputStep(e)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (termo == true) {
      requestData('post', 'conversions', {
        Veiculo,
        Prazo,
        Franquia,
        name,
        email,
        Cpf,
        Cep,
        Celular,
        legal_bases: [
          {
            category: 'communications',
            type: 'consent',
            status: 'granted',
          },
          {
            category: 'data_processing',
            type: 'consent',
            status: 'granted',
          },
        ],
        token_rdstation: '36ef8b1445846a96de8abe06eaf566be',
        identificador: 'Site - Simulação',
        traffic_source: cookies?.['__trf.src'] || cookies?.__utmzz,
        __utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
      }).then((data) => {
        if (data.result === 'success') navigate('/obrigado')
        else alert(data.msg)
      })
      navigate('/obrigado')
    }
  }

  return (
    <section className={cn(st.carListContainer, 'page-width')}>
      <h2>Solicite uma simulação em apenas 3 passos simples!</h2>
      <ul id="veiculos" className={st.steps}>
        <li id="1" className={cn({ [st.active]: stepActive == 1 })}>
          1 <span>Escolha seu veículo</span>
        </li>
        <li id="2" className={cn({ [st.active]: stepActive == 2 })}>
          2 <span>Personalize seu plano</span>
        </li>
        <li id="3" className={cn({ [st.active]: stepActive >= 3 })}>
          3 <span>Saia de carro 0km</span>
        </li>
      </ul>

      <div className={cn(st.stepOne, { [st.active]: stepActive === 1 })}>
        <p className={st.subtitle}>
          Selecione o modelo do veículo que você deseja contratar.
        </p>
        <ul className={st.carList}>
          {vehicles.map((car) => (
            <li className={st.carCard} key={car.id}>
              <LazyImage
                src={car.image}
                placeholder={({ ref }) => (
                  <img ref={ref} src={`${car.title} - ${car.subtitle}`} />
                )}
                actual={({ imageProps }) => <img {...imageProps} />}
              />
              <section className={st.nameAndDescription}>
                {isMobile && <span>Modelo</span>}
                <h3>
                  {car.title}
                  {car.electric && (
                    <LazyImage
                      src={electrical}
                      placeholder={({ ref }) => (
                        <img
                          ref={ref}
                          src={electrical}
                          alt="Simbolo para carro elétrico!"
                        />
                      )}
                      actual={({ imageProps }) => <img {...imageProps} />}
                    />
                  )}
                </h3>
                <p>{car.subtitle}</p>
              </section>
              <span className={st.category}>
                {isMobile && <span>Categoria</span>}
                {car.bodywork}
              </span>
              <div className={st.price}>
                {isMobile && <span>Valor</span>}
                <p>
                  A partir de: <b>{car.price}</b>
                </p>
                <small>{car.observation}</small>
              </div>
              <button
                onClick={(e) =>
                  handleSelectedCar(
                    e,
                    car.image,
                    car.title,
                    car.subtitle,
                    car.electric
                  )
                }
                className={st.select}
              >
                Selecionar veículo
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className={cn(st.stepTwo, { [st.active]: stepActive === 2 })}>
        <div className={st.containerCar}>
          <p className={st.titleCard}>modelo escolhido</p>
          <div className={st.carCard}>
            <LazyImage
              src={carImage}
              placeholder={({ ref }) => (
                <img ref={ref} src={`${carSelected} - ${carSubtitle}`} />
              )}
              actual={({ imageProps }) => <img {...imageProps} />}
            />
            <section className={st.nameAndDescription}>
              <h3>
                {carSelected}
                {carElectric && (
                  <LazyImage
                    src={electrical}
                    placeholder={({ ref }) => (
                      <img
                        ref={ref}
                        src={electrical}
                        alt="Simbolo para carro elétrico!"
                      />
                    )}
                    actual={({ imageProps }) => <img {...imageProps} />}
                  />
                )}
              </h3>
              <p>{carSubtitle}</p>
            </section>
            <a
              onClick={(e) => handleChangeVehicle(e)}
              className={st.changeVehicle}
            >
              Alterar veículo
            </a>
          </div>
        </div>
        <p className={st.subtitle}>
          Selecione o prazo mais a franquia que você deseja contatar e clique em
          avançar
        </p>
        <ul>
          <li>
            <p>
              Prazo
              <span onClick={handleTooltip}>
                i
                <span className={cn(st.tooltip, { [st.active]: openTooltip })}>
                  Selecione aqui o período que você deseja ficar com o veículo.
                </span>
              </span>
            </p>
            <form onSubmit={handleStepSubmit}>
              {deadlines.map((deadline) => (
                <label key={deadline.id}>
                  {deadline.title}
                  <input
                    type="radio"
                    checked={time === deadline.title}
                    name="deadline"
                    value={deadline.title}
                    onChange={(e) => setTime(e.target.value)}
                    required
                  />
                </label>
              ))}
              <button
                type="submit"
                ref={submitBtns[0]}
                style={{ display: 'none' }}
              ></button>
            </form>
          </li>
          <li>
            <p>
              Franquia
              <span onClick={handleTooltip}>
                i
                <span className={cn(st.tooltip, { [st.active]: openTooltip })}>
                  Selecione aqui a distância que você pretente utilizar o
                  veículo no mês.
                </span>
              </span>
            </p>
            <form onSubmit={handleStepSubmit}>
              {franchises.map((franchise) => (
                <label key={franchise.id}>
                  {franchise.title}
                  <input
                    type="radio"
                    name="franchise"
                    checked={franchisee === franchise.title}
                    value={franchise.title}
                    onChange={(e) => setFranchisee(e.target.value)}
                    required
                  />
                </label>
              ))}
              <button
                type="submit"
                ref={submitBtns[1]}
                style={{ display: 'none' }}
              ></button>
            </form>
          </li>
        </ul>
        <button onClick={(e) => handleStep(e)}>
          <span className="icon-right"></span> Avançar
        </button>
      </div>

      <div className={cn(st.stepThree, { [st.active]: stepActive === 3 })}>
        <div className={st.containerCar}>
          <p className={st.titleCard}>modelo escolhido</p>
          <div className={st.carCard}>
            <LazyImage
              src={carImage}
              placeholder={({ ref }) => (
                <img ref={ref} src={`${carSelected} - ${carSubtitle}`} />
              )}
              actual={({ imageProps }) => <img {...imageProps} />}
            />
            <section className={st.nameAndDescription}>
              <h3>
                {carSelected}
                {carElectric && (
                  <LazyImage
                    src={electrical}
                    placeholder={({ ref }) => (
                      <img
                        ref={ref}
                        src={electrical}
                        alt="Simbolo para carro elétrico!"
                      />
                    )}
                    actual={({ imageProps }) => <img {...imageProps} />}
                  />
                )}
              </h3>
              <p>{carSubtitle}</p>
            </section>
            <a
              onClick={(e) => handleChangeVehicle(e)}
              className={st.changeVehicle}
            >
              Alterar veículo
            </a>
          </div>
        </div>

        {appDriver === 'nao' ||
          (appDriver === '' ? (
            <div className={st.appDriver}>
              <h2>Você é motorista de aplicativo?</h2>
              <div className={st.buttons}>
                <button
                  value="sim"
                  className={st.select}
                  onClick={(e) => handleAppDriver(e)}
                >
                  sim
                </button>
                <button
                  value="nao"
                  className={st.select}
                  onClick={(e) => handleAppDriver(e)}
                >
                  não
                </button>
              </div>
            </div>
          ) : (
            <div className={st.appDriver}>
              <p>
                Infelizmente a Dimas Sempre ainda não está trabalhando com essa
                modalidade.
              </p>
            </div>
          ))}

        {appDriver == 'nao' && (
          <>
            <p className={st.subtitle}>
              Preencha o formulário abaixo para que nossos consultores entrem em
              contato.
            </p>
            <form onSubmit={handleStepSubmit}>
              <div className={cn({ [st.active]: inputStep === 1 })}>
                <fieldset>
                  <legend>Nome</legend>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Como você se chama?"
                    required
                  />
                  <button
                    type="button"
                    ref={submitBtns[2]}
                    style={{ display: 'none' }}
                  ></button>
                </fieldset>
                <button onClick={handleNameStep} type="button">
                  Próximo
                </button>
              </div>
            </form>
            <form onSubmit={handleStepSubmit}>
              <div className={cn({ [st.active]: inputStep === 2 })}>
                <fieldset>
                  <legend>E-mail</legend>
                  <input
                    type="email"
                    name="email"
                    pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Qual seu e-mail?"
                    required
                  />
                  <button
                    type="button"
                    ref={submitBtns[3]}
                    style={{ display: 'none' }}
                  ></button>
                </fieldset>
                <button type="button" onClick={handleEmailStep}>
                  Próximo
                </button>
              </div>
            </form>
            <form onSubmit={handleStepSubmit}>
              <div className={cn({ [st.active]: inputStep === 3 })}>
                <fieldset>
                  <legend>CPF</legend>
                  <input
                    type="text"
                    name="cpf"
                    id="cpf"
                    onChange={(e) => setCpf(maskCpf(e.target.value))}
                    value={cpf}
                    placeholder="Qual seu CPF?"
                  />
                  <button
                    type="button"
                    ref={submitBtns[4]}
                    style={{ display: 'none' }}
                  ></button>
                </fieldset>
                <button type="button" onClick={handleCpfStep}>
                  Próximo
                </button>
              </div>
            </form>
            <form onSubmit={handleStepSubmit}>
              <div className={cn({ [st.active]: inputStep === 4 })}>
                <fieldset>
                  <legend>CEP</legend>
                  <input
                    type="text"
                    name="cep"
                    id="cep"
                    onChange={(e) => setCep(maskCep(e.target.value))}
                    value={cep}
                    placeholder="Qual seu CEP?"
                    required
                  />
                  <button
                    type="button"
                    ref={submitBtns[5]}
                    style={{ display: 'none' }}
                  ></button>
                </fieldset>
                <button type="button" onClick={handleCepStep}>
                  Próximo
                </button>
              </div>
            </form>
            <form onSubmit={handleSubmitAssign} id="form-assign">
              <div className={cn({ [st.active]: inputStep === 5 })}>
                <fieldset>
                  <legend>Whatsapp</legend>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    onChange={(e) => setPhone(formatPhone(e.target.value))}
                    value={phone}
                    placeholder="Digite seu número de contato"
                    required
                    minLength={14}
                    maxLength={15}
                  />
                  <button
                    type="submit"
                    ref={submitBtns[6]}
                    style={{ display: 'none' }}
                  ></button>
                </fieldset>
                <button type="submit">Próximo</button>
              </div>
            </form>
          </>
        )}

        {completeForm && (
          <div
            className={cn(st.completeForm, { [st.active]: inputStep === 6 })}
          >
            <section>
              <h3>Veículo</h3>
              <span>{carSelected}</span>
              <p>{carSubtitle}</p>
              <button onClick={handleCarEdit}>Editar veículo</button>
            </section>
            <section>
              <h3>Plano</h3>
              <span>
                Prazo: {time} <br />
                Franquia: {franchisee}
              </span>
              <button onClick={handlePlanEdit}>Editar plano</button>
            </section>
            <section>
              <h3>Seus dados</h3>
              <span>
                {name} <br />
                {email} <br />
                {cpf && (
                  <>
                    {cpf} <br />
                  </>
                )}
                {cep} <br />
                {phone}
              </span>
              <button onClick={handleDataEdit}>Editar meus dados</button>
            </section>
            <form onSubmit={handleSubmit}>
              <div className={st.privacy}>
                <label>
                  <input
                    type="checkbox"
                    name="termo"
                    onChange={handleTermo}
                    required
                  />
                  <small>
                    Eu concordo em receber comunicações. Ao informar meus dados,
                    eu concordo com a
                    <AnchorLink to="/politica-de-privacidade/">
                      Política de privacidade
                    </AnchorLink>
                    e<AnchorLink to="/termos-de-uso/">Termos de uso</AnchorLink>
                  </small>
                </label>
                <label>
                  <input
                    id="use_terms"
                    type="checkbox"
                    name="comunicacao"
                    onChange={handleComunicacao}
                    required
                  />
                  <small>
                    Autorizo o Grupo Dimas a armazenar meus dados pessoais para
                    enviar campanhas de marketing e informações sobre a empresa
                    nos canais: Telefone, Email e SMS.
                  </small>
                </label>
              </div>
              <button type="submit">Solicitar contato</button>
            </form>
          </div>
        )}
      </div>
    </section>
  )
}
